import {request as zoeRequest, RequestOptions} from '@zoefin/utils';

function request<T = any>(url: string, options?: RequestOptions): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    zoeRequest<T>(url, {
      withErrorManipulation: true,
      removeDataObject: true,
      ...options,
    })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
}

export default request;
