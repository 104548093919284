import {QuillDeltaToHtmlConverter} from 'quill-delta-to-html';
import {findWhere, isString} from 'underscore';

import {
  GetCertificationIcon,
  IsQuillDeltaEmpty,
  QuillDeltaToHtmlStr,
} from './index.d';

const DOCUMENTS_TYPE: Array<CompanyCategory['slug']> = [
  'adv',
  'crs',
  'solicitor_disclosure',
];

export const isQuillDelta = (quillStr: string): boolean => {
  let isQuill = false;

  try {
    const quillObj = JSON.parse(quillStr);

    isQuill = quillObj.ops;
  } catch {
    isQuill = false;
  }

  return isQuill;
};

export const isQuillDeltaEmpty: IsQuillDeltaEmpty = (quillStr) => {
  let isEmpty = false;

  try {
    const quillObj = JSON.parse(quillStr);

    if (
      Object.keys(quillObj).length === 1 &&
      quillObj.ops.length === 1 &&
      quillObj.ops[0].insert === '\n'
    ) {
      isEmpty = true;
    }
  } catch {
    isEmpty = true;
  }

  return isEmpty;
};

const defaultQuillOptions = {encodeHtml: false};
export const quillDeltaToHtmlStr: QuillDeltaToHtmlStr = (
  quillStr = '{}',
  options = defaultQuillOptions,
) => {
  try {
    const jsonStruct = JSON.parse(quillStr);
    const converter = new QuillDeltaToHtmlConverter(
      jsonStruct.ops || jsonStruct,
      options,
    );

    return converter.convert();
  } catch {
    return quillStr || null;
  }
};

export const getCertificationIcon: GetCertificationIcon = (certificationId) => {
  const certificationSelector: {[key: number]: string} = {
    1: 'icon_cfp@3x.png',
    2: 'icon_cfa@3x.png',
    3: 'icon_cpa@3x.png',
    4: 'icon_chfc@3x.png',
    5: 'icon_cima@3x.png',
    6: 'icon_cic@3x.png',
    7: 'icon_ea@3x.png',
    8: 'icon_pfs@3x.png',
    9: 'icon_clu@3x.png',
    10: 'icon_cpwa@3x.png',
    11: 'icon_rcip.png',
    12: 'icon_aep.png',
    13: 'icon_afc.png',
    14: 'icon_cdfa.png',
    15: 'icon_ceft.png',
    16: 'icon_crpc.png',
    17: 'icon_crps.png',
    18: 'icon_caia.png',
    19: 'icon_chfc_2.png',
    20: 'icon_cpwa_2.png',
    21: 'icon_jd.png',
  };

  return certificationSelector[certificationId];
};

export const getAdvisorNames = (
  advisor: Advisor | Prospect,
): {
  name: string;
  lastname: string;
  full: string;
} => ({
  name: advisor && advisor.name ? advisor.name.trim() : '',
  lastname: (advisor && advisor.last_name) || '',
  full: (advisor && advisor.full_name) || '',
});

export const getAdvisorCompany = (advisor: Advisor): Company => {
  if (!advisor || !advisor.experiences) return null;

  const primaryExperience = findWhere(advisor.experiences || [], {
    primary: true,
  });

  return primaryExperience && primaryExperience.company;
};

export const getCompanyLogo = (advisor: Advisor) => {
  const primaryExperience = advisor?.experiences?.find((exp) => exp?.primary);

  return primaryExperience?.company.companyDocuments?.find(
    ({category}) => category.slug === 'logo',
  );
};

export const getCompanyDocuments = (advisor: Advisor): CompanyDocument[] => {
  const primaryExperience = findWhere(advisor.experiences || [], {
    primary: true,
  });

  if (!primaryExperience) {
    return [];
  }

  const {companyDocuments} = primaryExperience.company;

  return companyDocuments?.filter(({category}) =>
    DOCUMENTS_TYPE.includes(category.slug),
  );
};

export const getAdvisorLocations = (locations: ZoeLocation[]): string => {
  let locationsString = '';

  if (locations && locations.length > 0) {
    locations.forEach((location, idx) => {
      locationsString += isString(location.city) ? location.city : '';
      locationsString +=
        isString(location.city) && isString(location.state)
          ? `, ${location.state}`
          : '';

      if (idx < locations.length - 1) {
        locationsString += ' | ';
      }
    });
  }

  return locationsString;
};

export const getAdvisorMiles: (match: Match, advisorId: number) => number = (
  match,
  advisorId,
) => {
  const filtered = ((match && match.suggestedAdvisors) || []).filter(
    (x) => x.advisor.id === advisorId,
  );
  const floatDistance =
    filtered[0] && filtered[0].scores && filtered[0].scores.distance;

  if (!floatDistance) return -1;

  return parseInt(floatDistance.toString(), 10);
};

export const numberToTextDistance: (distance: number) => string = (
  distance,
): string => {
  let count = 0;
  let tempDistance = 0;

  if (distance === 0) {
    return 'Less than 1 mile away';
  }

  if (distance > 50) {
    return 'Works Virtual';
  }

  do {
    count += 1;
    tempDistance += 5;
  } while (tempDistance < distance);

  return `Less than ${count * 5} miles away`;
};

export const renderDistanceWithAdvisor = (
  matchedAdvisor: SuggestedAdvisor,
  locationAsFallback?: boolean,
): string => {
  let currentDistance: number;
  let advisorLocation: ZoeLocation;

  if (matchedAdvisor) {
    currentDistance =
      matchedAdvisor.scores?.distance &&
      parseInt(`${matchedAdvisor.scores.distance}`, 10);

    if (matchedAdvisor.location) {
      advisorLocation = matchedAdvisor.location;
    } else {
      const Locations = matchedAdvisor.advisor?.locations || [];

      advisorLocation = findWhere(Locations, {principal: true});
    }
  }

  const locationArray: string[] = [];

  if (advisorLocation?.city) {
    locationArray.push(advisorLocation.city);
  }

  if (advisorLocation?.state) {
    locationArray.push(advisorLocation.state);
  }

  const locationString = locationArray.join(', ');

  const distanceTxt =
    typeof currentDistance === 'number'
      ? numberToTextDistance(currentDistance)
      : undefined;

  if (distanceTxt) {
    const parsedLocation = locationString && `(${locationString})`;

    return locationAsFallback
      ? distanceTxt
      : `${distanceTxt} ${parsedLocation}`;
  }

  return locationString || undefined;
};

type ReviewsData = {
  score: string;
  count: number;
  reviews: Review[];
  seeReviews: boolean;
  seeOnlyAverage: boolean;
};

export const getReviewsData = (advisor: Advisor): ReviewsData => {
  const data: ReviewsData = {
    score: undefined,
    count: advisor?.reviews?.length || 0,
    reviews: advisor?.reviews || [],
    seeOnlyAverage:
      (advisor?.profile?.reviews_average_score &&
        advisor?.profile.show_reviews) ||
      false,
    seeReviews:
      (advisor?.reviews?.length && advisor?.profile.show_reviews) || false,
  };

  if (advisor?.profile?.reviews_average_score) {
    data.score = advisor.profile.reviews_average_score.toFixed(1);
  } else if (advisor?.reviews?.length) {
    let totalScore = 0;

    advisor.reviews.forEach((review) => {
      totalScore += review.score;
    });

    data.score = (totalScore / data.count).toFixed(1);
  }

  return data;
};

export const advisorFullNameTrim = (fullName: string): string => {
  return fullName.split(',')[0] || '';
};

type Certification = {
  identifier: number;
  name: string;
  short_name: string;
};

export const getAdvisorVisibleCertifications = (
  certifications: Certification[],
): string => {
  let visibleCertifications = '';

  if (certifications?.length) {
    const currentCertifications = [...certifications];

    visibleCertifications = currentCertifications
      .splice(0, 3)
      .map((certification) => certification.short_name)
      .join(', ');
  }

  return visibleCertifications;
};
