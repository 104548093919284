export const INCLUDE_PARAMS =
  'profile,certifications,pricings,client_specializations,degrees,competencies,registeredStates,experiences,awards,educations,integrations,locations,images,calendar_preferences';
export const MATCH_PARAMS = `?include=${INCLUDE_PARAMS}`;

export const DEFAULT_REQUEST_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  // 'Content-Type': 'application/x-www-form-urlencoded',
};

export const US_ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;
export const FILE_EXTENSION_REGEXP = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim;
export const PHONE_MASK = [
  '(',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ')',
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const ZIP_MASK = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

export const LONG_FORM_DEFAULT = 'Alpha';
export const OLD_FORM_DEFAULT = 'Alpha_Old';
export const SHORT_FORM_DEFAULT = `${LONG_FORM_DEFAULT}_Short`;
export const PARTNER_FORM_DEFAULT = `${LONG_FORM_DEFAULT}_Partners`;
export const FLATFEE_FORM_DEFAULT = `${LONG_FORM_DEFAULT}_FlatFee`;

export const SELF_SCHEDULE_ROLE = 'Self Schedule';
export const SALES_VALID_ROLES: {[key: string]: number} = JSON.parse('{}');

export const DUMMY_ADVISOR_IDS = [5, 111];

export const STEPS_CONTENT: {
  id: number;
  title: string;
  info: string;
}[] = [
  {
    id: 1,
    title: 'Review Your Matches',
    info: 'These are the Zoe Certified advisors that best fit your profile. Not quite right? Call our Concierge team at 646-847-2106 to help refine your search.',
  },
  {
    id: 2,
    title: 'Schedule an Introductory Call',
    info: 'It’s easy to interview one or more of your matches, simply find the date and time that works for you.',
  },
  {
    id: 3,
    title: 'Prepare For Your First Call',
    info: 'You’ll receive a text reminder and a handy email guide prior to your call.',
  },
];

export const NAV_WIDTH = '224px';

export const USER_GOALS_OPTIONS = [
  {
    name: 'Retirement',
    value: 'retirement',
    icon: '/static/svg/umbrella-beach.svg',
  },
  {
    name: 'Home Purchase',
    value: 'home-purchase',
    icon: '/static/svg/icon-home-primary.svg',
  },
  {
    name: 'Taxes',
    value: 'taxes',
    icon: '/static/svg/icon_cash.svg',
  },
  {
    name: 'Family Planning',
    value: 'family-planning',
    icon: '/static/svg/icon_family.svg',
  },
  {
    name: 'Financial Windfall',
    value: 'financial-windfall',
    icon: '/static/svg/icon-taxes.svg',
  },
  {
    name: 'Investments',
    value: 'investments',
    icon: '/static/svg/icon-investments-primary.svg',
  },
];

export const USER_INCLUDES =
  'flags,custodian_account_parameters,assets,liabilities,incomes';

export const DEFAULT_REDIRECT_AFTER_LOGIN = '/dashboard/advisors';
